<template>
    <main>
        <div id="smart-makieta"></div>
    </main>
</template>
<script>
export default {
    name: "Smart",
    data() {
        return {}
    },
    mounted() {
    let smartMakietaScript = document.createElement('script');
      smartMakietaScript.setAttribute('src','https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/js/main.js');
      this.scriptEle = document.body.appendChild(smartMakietaScript);
      setTimeout(function(){
      document.onload=(function(){SM.init('42e83f28-0bc4-44b2-8d8a-b14765050c7f','smart-makieta', {}, {location: true, gallery: true, showPrice: true, autoOpenOnMobile: true })})()
    }, 500);
    const header = document.querySelector('.header');
    header.classList.add('smart-header');
    },
    beforeDestroy() {
        document.body.removeChild(this.scriptEle);
        const header = document.querySelector('.header');
        header.classList.remove('smart-header');
    }
}
</script>
<style lang="scss" scoped>
@import url('https://3destatesmartmakietaemb.z6.web.core.windows.net/v1/css/main.css');

#smart-makieta {
    width: 100%;
    font-weight: normal;
    height: calc(100vh - 140px);
    max-height: 75vw;
    position: relative;
    max-width: 100%;
    ._3desm__flat-table__th {
        text-align: center !important;
    }
    ._3desm__flat-table__td {
        text-align: center !important;
    }
}
</style>
