<template>
    <section class="only-image" :class="[data.custom_class]">
        <div class="only-image__bg"></div>
        <div class="only-image__container custom-container-left">
            <img :src="data.image.url" :alt="data.image.alt" data-aos="fade-up">
        </div>
    </section>
</template>

<script>
export default {
    name: "OnlyImage",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/breakpoints.scss";
.only-image {
    margin-bottom: 40px;
    position: relative;
    &__bg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
    &__container {
        img {
            margin: 0;
            display: block;
        }
    }
    &__margin {
        margin-top: -400px;
        @include breakpoint-max("mobile") {
            margin-top: 0;
        }
    }
}
.margin-top-and-z-index {
    margin-top: -480px;
    z-index: 999;
    @include breakpoint-max("v-large") {
        margin-top: -300px;
    }
    @include breakpoint-max("large") {
        margin-top: -400px;
    }
    @include breakpoint-max("tablet") {
        margin-top: -200px;
    }
    @include breakpoint-max("mobile") {
        margin-top: 0px;
    }
    img {
        max-width: 1100px;
        @include breakpoint-max("v-large") {
            max-width: 800px;
        }
    }  
}
</style>
