<template>
<footer class="footer">
    <div class="custom-container footer__flex">
        <div class="footer__half">
            <span>Acciona Nieruchomości Sp. z o.o. © 2023</span>
            <div class="footer__half-privacy">
                <a href="https://acciona-nieruchomosci.pl/polityka-prywatnosci/" target="_blank" v-if="locale == 'pl'">Polityka prywatności</a>
                <a href="#" @click.prevent="showPopup = true" id="polytics" v-else-if="locale == 'ua'">Політика конфіденційності</a>
                <a href="#" @click.prevent="showPopup = true" id="polytics" v-else>Privacy policy</a>
            </div>
        </div>
        <div class="footer__half footer__half--right">
            <div class="header__social-nav">
                <a target="_blank" href="https://www.facebook.com/accionanieruchomosci/">FACEBOOK</a>
                <a target="_blank" href="https://www.instagram.com/accionanieruchomosci/">INSTAGRAM</a>
            </div>
        </div>
    </div>
</footer>
</template>

<script>
export default {
    computed: {
        locale() {
            return this.$store.getters.getLocale;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.footer {
    background-color: $secondBackgroundColor;
    padding: 40px 0;
    box-shadow: inset 0px 4px 5px -4px rgba(0, 0, 0, 0.3);

    &__flex {
        display: flex;
        @include breakpoint-max('mobile') {
            flex-direction: column;
        }

    }

    &__half {
        flex: 1;
        color: #000;
        display: flex;
        
        a {
            text-decoration: none;
            color: #000;
            margin-left: 40px;
            @include breakpoint-max('s-tablet') {
            margin-left: 10px;

        }
        }

        &--right {
            text-align: right;
            justify-content: flex-end;
             @include breakpoint-max('mobile') {
            justify-content: flex-start;
            a:nth-of-type(1) {
              margin-left: 0;
            }
        }
        }
    }
}
</style>
