<template>
<section class="text-and-image" :class="[data.custom_class]">
    <div class="text-and-image__bg"></div>
    <div class="text-and-image__container custom-container">
        <div class="text-and-image__image" v-if="data.image.image" :style="{order:data.image.responsive.fullhd_grid.order}" :data-aos="switchDirection(data.image.responsive.fullhd_grid.order)">
            <h1 v-if="data.title" class="text-and-image__heading" :data-aos="switchDirection(data.content.responsive.fullhd_grid.order)">{{ data.title }}</h1>
            <p v-if="data.subtitle" class="text-and-image__subheading" :data-aos="
    switchDirection(data.content.responsive.fullhd_grid.order)
  ">{{ data.subtitle }}</p>

            <img :src="data.image.image" :alt="data.image.alt">
        </div>
        <div class="text-and-image__text" :style="{order:data.content.responsive.fullhd_grid.order}">

            <div v-if="data.content.content" v-html="data.content.content" :data-aos="switchDirection(data.content.responsive.fullhd_grid.order)"></div>
            <router-link v-if="data.href" class="text-and-image__link" :to="data.href" :data-aos="switchDirection(data.content.responsive.fullhd_grid.order)">{{ data.link_text }}
            </router-link>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "TextAndImage",
    data() {
        return {}
    },
    methods: {
        switchDirection(arg) {
            switch (arg) {
                case '1':
                    return 'fade-right'
                    break;
                case '2':
                    return 'fade-left'
                    break;
                default:
                    return 'fade-right'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

//custom classes for modify default component layout
.narrow-image {
    .text-and-image__image {
        img {
            width: 80%;
            position: relative;

            @include breakpoint-max("mobile") {
                width: 100%;
                max-height: 450px;
            }
        }
    }
}

.mobile-col-reverse {
    .text-and-image__container {
        @include breakpoint-max("mobile") {
            flex-direction: column-reverse;
        }
    }
}

.text-right {
    .text-and-image__text {
        padding: 100px 100px 100px 0;

        @include breakpoint-max("tablet") {
            padding: 80px 80px 80px 0;
        }

        @include breakpoint-max("s-tablet") {
            padding: 60px 30px 60px 0;
        }

        @include breakpoint-max("mobile") {
            padding: 40px 0;
        }
    }
}

.text-left {
    .text-and-image__text {
        padding: 0 80px 100px 50px;

        @include breakpoint-max("large ") {
            padding: 50px;
        }

        @include breakpoint-max("tablet") {
            padding: 80px;
        }

        @include breakpoint-max("s-tablet") {
            padding: 60px;
        }

        @include breakpoint-max("mobile") {
            padding: 40px 0;
        }
    }
}

.text-flex-5 {
    .text-and-image__text {
        flex: 5;

        @include breakpoint-max("mobile") {
            flex: 12;
        }
    }

    .text-and-image__image {
        flex: 7;

        @include breakpoint-max("mobile") {
            flex: 12;
        }
    }
}

.text-blue {
    .text-and-image__text {
        color: $darkBlueHeading;
    }
}

.text-small {
    .text-and-image__text {

        @include breakpoint-max("s-tablet") {
            padding: 60px 0 60px 30px;
        }

        @include breakpoint-max("mobile") {
            padding: 50px 0;
        }
    }
}

.text-padding-left {
    .text-and-image__text {
        padding-left: 100px;
        padding-right: 30px;

        @include breakpoint-max("s-tablet") {
            padding: 60px 30px 60px 0px;
        }

        @include breakpoint-max("mobile") {
            padding: 50px 0;
        }
    }
}

.text-tablet-padding-y {
    .text-and-image__text {
        @include breakpoint-max("s-tablet") {
            padding: 60px 0;
        }

        @include breakpoint-max("mobile") {
            padding: 50px 0;
        }
    }
}

.extra-padding-bottom {
    padding-bottom: 150px;

    @include breakpoint-max("large") {
        padding-bottom: 130px;
    }

    @include breakpoint-max("tablet") {
        padding-bottom: 80px;
    }

    @include breakpoint-max("mobile") {
        padding-bottom: 0;
    }
}

.extra-padding-top {
    padding-top: 150px;

    @include breakpoint-max("large") {
        padding-top: 130px;
    }

    @include breakpoint-max("tablet") {
        padding-top:
            80px;
    }

    @include breakpoint-max("mobile") {
        padding-top: 40px;
    }
}

.extra-padding {
    padding: 150px 0;

    @include breakpoint-max("large") {
        padding: 100px 0;
    }

    @include breakpoint-max("tablet") {
        padding: 80px 0;
    }

    @include breakpoint-max("mobile") {
        padding: 40px 0;
    }
}

.extra-padding-y-mobile {
    .text-and-image__text {
        @include breakpoint-max("mobile") {
            padding: 40px 0;
        }
    }
}

.image-box-narrow {
    .text-and-image__image {
        width: 40%;

        @include breakpoint-max("large") {
            padding-right: 30px;
        }

        @include breakpoint-max("s-tablet") {
            padding-right: 0;
        }

        @include breakpoint-max("mobile") {
            width: 100%;
        }
    }
}

.image-natural-size-right {
    .text-and-image__image {
        display: flex;
        justify-content: flex-end;

        @include breakpoint-max("mobile") {
            justify-content: flex-start;
        }

        img {
            height: auto;
            width: auto;

            @include breakpoint-max("mobile") {
                width: 100%;
                height: 100%;
                max-height: 350px;
                max-width: 350px;
            }
        }
    }
}

.image-natural-size-left {
    .text-and-image__image {
        display: flex;
        justify-content: flex-start;

        @include breakpoint-max("mobile") {
            justify-content: flex-start;
        }

        img {
            height: auto;
            width: auto;

            @include breakpoint-max("mobile") {
                width: 100%;
                height: 100%;
                max-height: 350px;
                max-width: 350px;
            }
        }
    }
}

.text-and-image {
    position: relative;

    &__bg {
        height: 100%;
        width: 100%;
        z-index: 700;
    }

    &__container {
        z-index: 800;
        display: flex;

        @include breakpoint-max("mobile") {
            flex-direction: column;
        }
    }

    &__text {
        padding-left: 100px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint-max("tablet") {
            font-size: 16px;
        }

        @include breakpoint-max("mobile") {
            width: 100%;
            padding-left: 0;
        }

        /deep/ div {
            display: flex;
            align-items: left;
            flex-direction: column;

            h2 {
                font-size: 2.2em;
                margin-bottom: 50px;
            }

            a {
                width: 200px;
                height: 55px;
                background-color: $darkGranat;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
            }

        }

        /deep/ p:not(:last-child) {
            margin-bottom: 24px;

        }
    }

    &__subheading {
        margin-bottom: 50px;
        display: inline-block;
    }

    &__heading {

        font-weight: 400;
        font-size: 2.2em;
        margin: 40px 0;
    }

    &__image {
        width: 50%;

        @include breakpoint-max("mobile") {
            width: 100%;
        }
    }

    &__link {
        text-transform: uppercase;
        color: $darkBlueHeading;
        letter-spacing: 1.25px;
        display: flex;
        align-items: center;
        margin-top: 24px;

        &::before {
            content: "";
            line-height: 1;
            display: inline-block;
            background-image: url("../../assets/arrow.min.svg");
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            width: 1.5rem;
            height: 1.25rem;
            vertical-align: middle;
            margin-right: 10px;
            transition: all .35s ease-in-out;
        }
    }
}
.list-item {
    padding: 100px 0;
    border-bottom: none;
    .text-and-image__text {
        padding-left: 0;
        @include breakpoint-max("large") {
            margin-left: 30px;
        }
       /deep/ h2 {
            line-height: 1;
        }
       /deep/ ul {
            list-style: disc;
            margin-bottom: 50px;
            padding-left: 20px;
            line-height: 2;
        }
        /deep/ img {
            margin-left: -300px;
            @include breakpoint-max("large") {
            margin-left: 0;
        }
        }
    }
    
}
.list-item:not(:last-child) {
        border-bottom: none;
    }
</style>
