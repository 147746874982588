<template>
<header class="header">
    <div class="header__top">
        <div class="custom-container-full">
            <div class="header__flex">
                <div class="header__logo">
                    <a class="" href.prevent="#" @click.prevent="$router.push('/')">
                        <img :src="optionsLogo" />
                    </a>
                    <div class="header__language">
                        <div class="header__lang-switcher" v-if="languages.length > 1">
                            <a class="header__navbar-link header__navbar-link--current" v-for="(lang, index) in languages" @click="menuLang = !menuLang" :href="langHomeUrl(lang.home_url)" v-if="lang.lang == locale" :key="index">{{ lang.name }}</a>
                            <div class="header__navbar-dropdown">
                                <a class="header__navbar-link header__navbar-link--hidden" v-for="(lang, index) in languages" :href="langHomeUrl(lang.home_url)" v-if="lang.lang != locale" :key="index">{{ lang.name }}</a>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="header__right">
                    <div class="header__right-wrap">
                        <div class="header__right-top">
                            <div class="header__menu">
                                <nav  v-if="locale === 'pl'" class="header__navbar" role="navigation" aria-label="main navigation">
                                    <div class="header__items" v-for="(menuItem, menuKey) in mainMenu" :key="menuKey">
                                        <a :href="'/' + menuLocale + menuItem.url" @click.prevent="menuItem.children ? '' : navigateTo('/' + menuItem.url)" v-html="menuItem.title"></a>
                                    </div>
                                    <div  class="header__items" >
                                        <a target="_blank" href="https://acciona-nieruchomosci.pl/blog/" v-html="'Blog ACCIONA'"></a>
                    
                                    </div>
                                </nav>
                                <nav  v-if="locale === 'en'" class="header__navbar" role="navigation" aria-label="main navigation">
                                    <div class="header__items">
                                        <router-link to="/explore-acciona" v-if="locale == 'en'">ACCIONA</router-link>
                                    </div>
                                    <div class="header__items">    
                                        <router-link to="/explore-blanco" v-if="locale == 'en'">Explore BLANCO </router-link>
                                    </div>
                                    <div class="header__items">    
                                        <router-link to="/contact" v-if="locale == 'en'">Contact</router-link>
                                    </div>
                                </nav>
                                <transition v-on:enter="menuEnter" v-on:leave="menuLeave" appear>
                                    <nav class="header__mobile" v-if="menuActive" role="navigation" aria-label="main navigation">
                                        <div class="header__mobilenav">
                                            <div>
                                                <a v-if="locale === 'pl'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/znajdz-mieszkanie')">Znajdź mieszkanie</a>
                                                <a v-if="locale === 'en'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/search-apartments')">Search Apartments</a>
                                                <!-- <a v-if="locale === 'ua'" class="header__links" href="/ua/search-apartments">Пошук квартир</a> -->
                                            </div>
                                            <div>
                                                <a v-if="locale === 'pl'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/lokalizacja')">Poznaj okolice</a>
                                                <a v-if="locale === 'en'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/explore-the-neighborhood')">Explore the neighborhood</a>
                                                <!-- <a v-if="locale === 'ua'" class="header__links" href="/ua/search-apartments">Пошук квартир</a> -->
                                            </div>
                                             <div>
                                                <a v-if="locale === 'pl'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/wykonczenie-pod-klucz')">Wykończenie pod klucz</a>
                                                <a v-if="locale === 'en'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/turn-key-finish')">Turn-key finish</a>
                                            </div>
                                            <div>
                                                <a v-if="locale === 'pl'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/poznaj-blanco')">Poznaj BLANCO</a>
                                                <!-- <a v-if="locale === 'en'" class="header__links" href="/en/explore-blanco/">Explore Blanco</a>
                                                <a v-if="locale === 'ua'" class="header__links" href="/ua/search-apartments">Пошук квартир</a> -->
                                            </div>
                                            <div>
                                                <a v-if="locale === 'pl'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/dziennik-budowy')">Dziennik budowy</a>
                                                <a v-if="locale === 'en'" class="header__links" v-on:click="close" href.prevent="#" @click="$router.push('/construction-log')">Construction log</a>
                                                 <!--<a v-if="locale === 'ua'" class="header__links" href="/ua/search-apartments">Пошук квартир</a> -->
                                            </div>

                                            <div v-for="(menuItem, menuKey) in mainMenu" :key="menuKey">
                                                <a :href="'/' + menuLocale + menuItem.url" class="header__links" @click.prevent="menuItem.children ? '' : navigateTo('/' + menuItem.url)" v-html="menuItem.title"></a>
                                            </div>
                                            <div class="header__social-nav">
                                                <a
                                                target="_blank"
                                                href="https://www.facebook.com/accionanieruchomosci/"><img src="@/assets/fb.svg" alt="Facebok" /></a>
                                                <a
                                                target="_blank"
                                                href="https://www.instagram.com/accionanieruchomosci/"><img src="@/assets/instagram.svg" alt="Instagram" /></a>

                                            </div>
                                        </div>
                                    </nav>
                                </transition>
                            </div>
                            <div class="header__phone">
                                <div class="header__phone--icons"></div><span><a href="tel:+48224022595">+ 48 22 402 25 95</a></span>
                            </div>
                            <div class="header__social">
                                <a
                                target="_blank"
                                href="https://www.facebook.com/accionanieruchomosci/"><img src="@/assets/icon-facebook.svg" alt="Acciona facebook" /></a>
                                <a
                                target="_blank"
                                href="https://www.instagram.com/accionanieruchomosci/"><img src="@/assets/icon-instagram.svg" alt="Acciona intagram" /></a>
                            </div>

                        </div>
                        <div class="header__right-bottom">
                            <div class="header__menu-second">
                                <div class="header__menu-second-item">
                                    <router-link  to="/znajdz-mieszkanie" v-if="locale == 'pl'">
                                        Znajdź Mieszkanie
                                    </router-link>
                                   <router-link to="/search-apartments" v-if="locale == 'en'">See apartaments
                                    </router-link>
                                   <!--   <router-link to="search-apartments" v-if="locale == 'ua'">Пошук квартир
                                    </router-link> -->
                                </div>
                                <div class="header__menu-second-item">
                                    <router-link to="/lokalizacja" v-if="locale == 'pl'">
                                        Okolica
                                    </router-link>
                                    <router-link to="/explore-the-neighborhood" v-if="locale == 'en'"> Explore the neighborhood
                                    </router-link>
                                    <!-- <router-link to="search-apartments" v-if="locale == 'ua'">Пошук квартир
                                    </router-link> -->
                                </div>
                                <div v-if="locale == 'pl'" class="header__menu-second-item">
                                    <router-link to="/poznaj-blanco" v-if="locale == 'pl'">
                                        Poznaj BLANCO
                                    </router-link>
                                    <!-- <router-link to="explore-blanco" v-if="locale == 'en'">Explore Blanco
                                    </router-link>
                                    <router-link to="search-apartments" v-if="locale == 'ua'">Пошук квартир
                                    </router-link> -->
                                </div>
                                 <div class="header__menu-second-item">
                                    <router-link to="/wykonczenie-pod-klucz" v-if="locale == 'pl'">
                                        Wykończenie pod klucz
                                    </router-link>
                                    <router-link to="/turn-key-finish" v-if="locale == 'en'"> Turn-key finish
                                    </router-link>
                                </div>
                                <div class="header__menu-second-item">
                                    <router-link to="/dziennik-budowy" v-if="locale == 'pl'">
                                        Dziennik budowy
                                    </router-link>
                                    <router-link to="/construction-log" v-if="locale == 'en'">Construction log
                                    </router-link>
                                    <!-- <router-link to="search-apartments" v-if="locale == 'ua'">Пошук квартир
                                    </router-link> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="header__search">
                        <router-link to="/znajdz-mieszkanie" v-if="locale == 'pl'"><img src="@/assets/Search.svg" alt="" /><span>Znajdź mieszkanie</span></router-link>
                        <router-link to="/search-apartments" v-if="locale == 'en'"><img src="@/assets/Search.svg" alt="" /><span>Search Apartments</span></router-link>
                        <!-- <router-link to="search-apartments" v-if="locale == 'ua'"><img src="@/assets/Search.svg" alt="" /><span>Пошук квартир</span></router-link> -->
                    </div>
                    <button class="header__hamburger" :class="{ 'active-burger': menuActive }" @click="menuActive = !menuActive"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="header__padding"></div>
</header>
</template>

<script>
import { TimelineLite, Back, Elastic, Expo } from "gsap";

export default {
    data() {
        return {
            burger: false,
            menuLang: false,
            menuActive: false,
        };
    },
    methods: {
        close() {
            this.burger = false;
            this.menuActive = false;
        },
        langHomeUrl(home_url) {
            return this.publicPath + home_url;
        },
        navigateTo(url) {
            const publicPath = "/" + this.$store.getters.getPublicPath;
            const locale = this.$store.getters.getLocale;
            if (url != "#") {
                this.$router.push(url);
                this.burger = false;
                this.menuActive = false;
            }
        },
        menuEnter(el, done) {
            const tl2 = new TimelineMax({
                onComplete: done,
            });

            tl2.set(el, {
                scaleY: 0,
                transformOrigin: "0% top",
            });

            tl2.to(el, 0.5, {
                scaleY: 1,
                transformOrigin: "100% top",
                ease: Power2.easeOut,
            });

            tl2.staggerFrom(".header__links", 1, { opacity: 0 }, 0.15);
        },
        menuLeave(el, done) {
            const tl3 = new TimelineMax({
                onComplete: done,
            });

            tl3.to(".header__links", 0.3, { opacity: 0 }, 0.15);

            tl3.to(el, 0.3, {
                scaleY: 0,
                ease: Power2.easeOut,
                onComplete: done,
            });
        },
    },
    computed: {
        languages() {
            return this.$store.getters.getLanguages;
        },
        options() {
            return this.$store.getters.getOptions;
        },
        locale() {
            return this.$store.getters.getLocale;
        },
        menuLocale() {
            return this.locale !== this.$store.getters.getOptions.default_language ?
                this.locale + "/" : "";
        },
        publicPath() {
            return this.$store.getters.getPublicPath;
        },
        mainMenu() {
            return this.$store.getters.getMainMenu;
        },
        optionsLogo() {
            return this.$store.getters.getOptionsLogoFull;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.header {
    &__flex {
        height: 140px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 10px 20px #0000000D;

        @include breakpoint-max("tablet") {
            height: 100px;
        }
    }

    &__top {
        background-color: $secondBackgroundColor;
        position: fixed;
        z-index: 9999999;
        width: 100%;

        @include breakpoint-max("s-tablet") {
            box-shadow: 2px 2px 6px 2px rgba(134, 134, 134, 0.2);
        }
    }

    &__hamburger {
        display: none;
        padding: 10px;
        cursor: pointer;
        background-color: transparent;
        border: none;
        margin-left: 10px;
        outline: none;

        @include breakpoint-max("tablet") {
            display: block;
        }

        &::before,
        &::after {
            transition: transform 0.2s ease;
            content: "";
            width: 25px;
            height: 1px;
            background-color: #000;
            display: block;
        }

        &::after {
            margin-top: 5px;
        }

        &.active-burger {
            &::before {
                transform: rotate(45deg);
            }

            &::after {
                margin-top: -1px;
                transform: rotate(-45deg);
            }
        }
    }

    &__logo {
        flex: 1;
        background-color: #000;
        text-align: center;
        padding: 36px 0;
        display: flex;
        justify-content: space-around;
        max-width: 606px;
        min-width: 606px;

        @include breakpoint-max("v-large") {
            flex: 1;
        }

        @include breakpoint-max("large") {
            max-width: 450px;
            min-width: 450px;
        }

        @include breakpoint-max("desktop") {
            max-width: 410px;
            min-width: 410px;
        }

        @include breakpoint-max("tablet") {
            flex: 2;
            padding: 20px;
            max-width: initial;
            min-width: 100px;

        }

        @include breakpoint-max("mobile") {
            flex: 80%;
            flex-direction: column;
            align-items: center;
        }
        img {
            @include breakpoint-max("mobile") {
                max-height: 40px;
            }

        }
        
    }

    &__right {
        flex: 2;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-left: 0;
        padding: 36px 0;

        @include breakpoint-max("v-large") {
            margin-left: 10px;
        }

        @include mobile {
            margin-left: 20px;
        }

        &-wrap {
            width: 100%;
            font-weight: 300;
        }

        &-top {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-bottom: 15px;
        }
    }

    &__items {
        padding-right: 45px;

        @include breakpoint-max("large") {
            padding-right: 20px;
        }
        a {
            color: #000 !important;

        }
    }
    &__search,
    &__phone {
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
        }
    }

    &__phone {
        margin-right: 30px;
        min-width: 175px;

        a {
            color: #000;

        }
    }

    &__padding {
        padding-top: 140px;

        @include breakpoint-max("tablet") {
            padding-top: 100px;
        }
    }

    &__search {
        background-color: $lightRed;
        height: 100%;
        padding: 0 10px;
        font-size: 1rem;
        width: 270px;
        margin-left: 80px;
        font-weight: 700;

        @include breakpoint-max("v-large") {
            margin-left: 30px;
            width: 200px;
        }

        @include breakpoint-max("desktop") {
            display: none;
        }

        a {
            display: flex;
            align-items: center;

            span {
                color: #fff;
                display: block;
            }
        }

        @include breakpoint-max("s-tablet") {
            display: none;
        }
    }

    &__phone {
        @include touch {
            padding-right: 4px;
        }

        @include breakpoint-max("tablet") {
            display: none;
        }

        &--icons {
            height: 16px;
            width: 16px;
            background-image: url('~@/assets/phone-new.svg');

        }
    }

    &__social {
        display: flex;
        align-items: center;

        @include touch {
            display: none;
        }

        a {
            margin: 0 7px 0 5px;

        }
    }

    &__social-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        a {
            margin-top: 7px;

            img {
                margin: 5px;
                height: 20px;
            }
        }
    }

    &__navbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: #fff;

        @include breakpoint-max("tablet") {
            display: none;
        }
    }

    &__mobile {
        position: fixed;
        width: 100%;
        height: calc(100vh - 100px);
        z-index: 999;
        top: 100px;
        left: 0;
        background-color: rgba(0, 0, 0, 0.9);

        @include breakpoint-min("tablet") {
            display: none;
        }
    }

    &__mobilenav {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 95%;

        a {
            color: #fff;
            font-weight: 500;
            font-size: 24px;
            transition: color 0.3s ease;
            line-height: 50px;

            &:hover {
                color: $lightBlue;
            }
        }
    }

    &__items {
        a {
            color: #28293D;
        }

    }

    &__menu-second {
        display: flex;
        justify-content: space-between;
        padding-right: 7px;
        width: 90%;
        margin: auto;
        margin-right: 0;
        @media(min-width: 2500px) {
          width: 95%;
        }
        @include breakpoint-max("v-large") {
            width: 100%;
        }

        @include breakpoint-max("tablet") {
            display: none;
        }
        a {
            font-size: 1.1em;
            font-weight: 700;
            color: $black;
            @include breakpoint-max("v-large") {
            font-size: 1em;
            }
        }
    }
    &__language {
        @include mobile {
            display: flex;
            align-items: center;
        }

        @include breakpoint-max("tablet") {
            align-items: flex-start;
        }
    }

    &__lang-switcher,
    &__navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        align-items: stretch;
        display: flex;
    }

    &__lang-switcher {
        align-items: stretch;
        padding: 0;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        color: #4a4a4a;
        display: block;
        line-height: 1.5;
        padding: 0.5rem 0.75rem;
        position: relative;
        transition: background-color 0.2s ease-in-out;

        &:hover {
            background-color: darken(white, 10%);
            cursor: pointer;

            @include touch {
                background: none;
            }
        }

        &:hover .header__navbar-dropdown {
            display: block;
        }

        @include touch {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-right: 15px;

            a {
                display: block;
                float: left;
                padding: 5px;
                align-self: center;
            }
        }
    }

    &__navbar-dropdown {
        background-color: #fff;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 2px solid #dbdbdb;
        box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
        display: none;
        font-size: 0.875rem;
        left: 0;
        min-width: 100%;
        position: absolute;
        top: 100%;
        z-index: 20;

        @include touch {
            line-height: 1.8;
            background: none;
            display: block;
            border: none;
            position: inherit;
            box-shadow: none;
        }

        @include mobile-m {}

        & .header__lang-switcher {
            padding-right: 3rem;
            padding: 0.375rem 1rem;
            white-space: nowrap;
        }
    }

    &__navbar-link {
        align-items: center;
        display: flex;
        color: #fff;

        @include touch {
            padding: 0.05rem;
        }

        @include mobile-m {
            font-size: 0.7rem;
        }

        @include mobile-xs {
            font-size: 0.6rem;
            line-height: 1;
        }

        &--current {
            padding-right: 1.2em;
            align-items: center;
            position: relative;

            @include touch {
                padding-right: 0.5rem;
                font-weight: bold;
                color: #fff;
            }

            &::after {
                content: '';
                height: 12px;
                width: 14px;
                background-image:
                    url('~@/assets/arrow-down.svg');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: 7px;
                right: 0;
                filter: invert(100%) sepia(4%) saturate(121%) hue-rotate(322deg) brightness(118%) contrast(100%);

                @include touch {
                    display: none;
                }
            }
        }

        &--hidden {
            transition: background-color 0.2s ease-in-out;
            padding: 1rem;
            color: #000;

            @include breakpoint-max("tablet") {
                color: #fff;
            }

            @include touch {
                border-left: 1px solid gray;
                padding: 0.05rem 0.05rem 0.05rem 0.4rem;
            }

            &:hover {
                background-color: darken(white, 10%);
                color: #000;

                @include touch {
                    background: none;
                }
            }
        }
    }
}
.smart-header {
     @include breakpoint-max("mobile") {
        display: none;
    }
}
</style>
