<template>
<section class="sticky-right" :class="[data.custom_class]">
    <div class="sticky-right__background-div"></div>
    <div class="sticky-right__container custom-container-left" v-if="data.image.url" :style="{ backgroundImage: 'url(' +
data.image.url + ')' }">
        <div class="sticky-right__text-box">
            <span class="sticky-right__subheading" data-aos="fade-right">{{
          data.subtitle
        }}</span>
            <h2 class="sticky-right__heading" data-aos="fade-right">
                {{ data.title }}
            </h2>
            <div v-html="data.text" class="sticky-right__text" data-aos="fade-right"></div>
            <router-link v-if="data.href" class="sticky-right__link" :to="data.href" data-aos="fade-right">{{ data.link_text }}</router-link>
        </div>
        <div></div>
    </div>
</section>
</template>

<script>
export default {
    name: "StickyRightContainer",
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

//custom classes for modify default component layout
.bg-top {
    .sticky-right__background-div {
        top: 0;
    }
}

.sticky-right {
    display: flex;
    position: relative;
    z-index: 800;
    justify-content: flex-end;
    margin-top: -5px;
    min-height: 500px;

    @include breakpoint-max("large") {
        min-height: 300px;

    }

    &__background-div {
        background-color: $darkGranat;
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 700;
        margin-top: -5px;
    }

    &__container {
        background-color: #fff;
        display: flex;
        flex-wrap: wrap;
        z-index: 800;
        background-position: bottom;
        background-size: cover;

        @include breakpoint-max("xs-tablet") {
            flex-direction: column;
        }
    }

    &__text-box {
        flex: 5;
        padding: 170px 255px;
        font-size: 18px;
        font-weight: 300;
        background: rgba(0, 0, 0, 0.6);

        @include breakpoint-max("large") {
            padding: 100px 50px;

        }

        @include breakpoint-max("tablet") {
            padding: 80px 60px;
            font-size: 16px;
        }

        @include breakpoint-max("s-tablet") {
            padding: 70px 50px;
        }

        @include breakpoint-max("mobile") {
            padding: 40px 30px;
        }
    }

    &__subheading {
        margin-bottom: 5px;
        display: inline-block;

        @include breakpoint-max("tablet") {
            font-size: 17px;
        }
    }

    &__heading {
        color: #fff;
        font-size: 2.2em;

        @include breakpoint-max("tablet") {
            font-size: 22px;
        }
    }

    &__heading,
    &__text {
        margin-bottom: 24px;
    }

    &__image-box {
        flex: 7;
        width: 100%;
        background-size: cover;
        background-position: center;

        @include breakpoint-max("xs-tablet") {
            min-height: 350px;
        }
    }

    &__text {
        color: #fff;
        margin-bottom: 40px;
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $darkGranat;
        background-color: #fff;
        width: 200px;
        height: 55px;

    }
}
</style>
