<template>
  <div>
    <div class="pop" v-if="session && show">
      <div class="pop__box">
        <button class="pop__close" @click="hidePopup">
          <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512.001 512.001"
            style="enable-background: new 0 0 512.001 512.001"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  fill="#FFFFFF"
                  d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
                            L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
                            c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
                            l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
                            L284.286,256.002z"
                />
              </g>
            </g>
          </svg>
        </button>
        <a :href="data.url" title="popup">
          <img :src="data.background" alt="Popup" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      session: sessionStorage.getItem("hide") ? false : true,
    };
  },
  methods: {
    hidePopup() {
      sessionStorage.setItem("hide", false);
      this.show = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pop {
  display: none;
  background-color: rgba(20, 51, 117, 0.8);
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999999;
  &__box {
    img {
      max-width: 800px;
      max-height: 80vh;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }
  }
  &__close {
    width: 60px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 999999991;
    background: none;
    cursor: pointer;
    border: none;
  }
}
</style>
