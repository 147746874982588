<template>
<section class="text-and-two-photos" :class="[data.custom_class]">
    <dev class="text-and-two-photos__bg"></dev>
    <div class="text-and-two-photos__container custom-container">
        <div class="item item--1" data-aos="fade-right">
            <h2 v-html="data.text_title"></h2>
            <div v-html="data.text_content"></div>
        </div>

        <div class="item item--2">
            <img :src="data.first_image.url" :alt="data.first_image.alt" data-aos="fade-left">
            <p class="text-and-two-photos__image-description" v-html="data.first_image_description" data-aos="fade-left">{{ data.first_image_description }}</p>
        </div>
        <div class="item item--3">
            <img :src="data.second_image.url" :alt="data.second_image.alt" data-aos="fade-right">
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "TwoPhotosAndText",
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.text-and-two-photos {
    position: relative;

    @include breakpoint-max("tablet") {
        margin: 50px 0;
    }

    &__bg {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    &__container {
        display: grid;
        grid-template-rows: repeat(2, 50%);
        grid-template-columns: 2fr 3fr;

        @include breakpoint-max("mobile") {
            display: flex;
            flex-direction: column;
        }
    }

    &__image-description {
        margin-top: 10px;
    }

    .item {
        &--1 {
            padding: 120px 50px 120px 0;
            display: flex;
            justify-content: center;
            // align-items: center;
            font-weight: 300;
            flex-direction: column;
            text-align: left;

            @include breakpoint-max("tablet") {
                font-size: 16px;
                padding: 100px 40px 100px 40px;
            }

            @include breakpoint-max("mobile") {
                order: 2;
                padding: 40px 0;
            }

            /deep/ p {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }

            /deep/ a {
                font-weight: 700;
                display: flex;
                align-items: center;
                text-decoration: underline;
            }

            h2 {
                font-size: 2.2em;
                font-weight: 300;
                line-height: 1.2em;
                margin-bottom: 30px;
            }
        }

        &--2 {
            @include breakpoint-max("mobile") {
                order: 1;
            }

            & img {
                object-fit: cover;
                margin-top: -200px;
                margin-left: 60px;
                z-index: 999;
                position: relative;
                box-shadow: 415px 110px 0px -5px #000;

                @include breakpoint-max("notebook") {
                    margin-top: 0;
                    margin-left: 0;

                }

                @include breakpoint-max("tablet") {
                    box-shadow: none;
                }

            }
        }

        &--3 {
            padding-right: 20px;

            @include breakpoint-max("mobile") {
                order: 3;
                padding-right: 0;
            }

            & img {
                object-fit: cover;
            }
        }
    }

    &__fixed-picture {
        .text-and-two-photos__container {
            grid-template-columns: 1fr 2fr;
        }

        .item {
            &--1 {
                visibility: hidden;
            }

            &--2 {
                img {
                    margin-left: 0;
                    margin-top: -100px;
                    box-shadow: none !important;

                    @include breakpoint-max("mobile") {
                        margin-top: 0;
                    }

                }
            }

            &--3 {
                padding-right: 0;

                img {
                    display: block;
                    margin: auto;
                    margin-top: -260px;
                    margin-right: -20px;
                    z-index: 999;
                    position: relative;

                    @include breakpoint-max("mobile") {
                        margin: 0;
                    }

                }
            }
        }
    }

    &__no-margin {
        background-color: $darkGranat;

        .text-and-two-photos__container {
            grid-template-columns: 2fr 2fr;
        }

        .item {
            color: #fff;

            &--1 {
                /deep/ a {
                    width: 200px;
                    height: 55px;
                    background-color: #fff;
                    color: $darkGranat;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-decoration: none;
                }

            }

            &--2 {
                img {
                    display: block;
                    margin: auto;
                    margin-right: 0;
                    margin-top: 150px;
                    box-shadow: none !important;
                }
            }

            &--3 {
                padding-right: 0;

                img {
                    display: block;
                    margin: auto;
                    margin-right: -50px;
                    z-index: 999;
                    position: relative;

                    @include breakpoint-max("mobile") {
                        margin: 0;
                    }

                }
            }
        }
    }

}
</style>
