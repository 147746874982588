<template>
    <section class="only-text" :class="[data.custom_class]">
        <div class="only-text__bg"></div>
        <div class="custom-container">
            <div v-html="data.text" class="only-text__content" data-aos="fade-down"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OnlyText",
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/framework/variables.sass";
@import "@/styles/framework/breakpoints.scss";

.only-text {
    position: relative;
    &__bg {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        top: 0;
        left: 0;
    }
    &__content {
        padding: 100px 40px;
        width: 100%;
        max-width: 1000px;
        text-align: center;
        margin: 0 auto;
        @include breakpoint-max("s-tablet") {
            padding: 50px 10px;
        }
        @include breakpoint-max("xs-tablet") {
            padding: 0 0 50px;
        }
        /deep/ h2 {
            font-size: 36px;
            letter-spacing: 0;
            font-weight: 300;
            text-transform: uppercase;
            line-height: 1.3;
            color: $darkBlueHeading;
            @include breakpoint-max("s-tablet") {
                font-size: 28px;
            }
            @include breakpoint-max("mobile") {
                font-size: 24px;
            }
            /deep/ span {
                color: $lightBlue;
            }
        }
        /deep/ a {
            margin-top: 20px;
        }
    }
}
.margin-and-background {
    margin-top: -682px;
    min-height: 670px;
    
    @include breakpoint-max("v-large") {
        margin-top: -507px;
        min-height: 500px;
    }
    @include breakpoint-max("large") {
        margin-top: -507px;
        min-height: 600px;
    }
    @include breakpoint-max("notebook") {
        margin-top: 0;
        min-height: 200px;
    }
    .only-text__bg {
        background: #000;
    }
    .only-text__content {
        color: #fff;
        position: absolute;
        right: 0;
        top: 270px;
        max-width: 500px;
        text-align: left;
        @include breakpoint-max("custom-large") {
            max-width: 410px;
    }
         @include breakpoint-max("v-large") {
            top: 100px;
        }
         @include breakpoint-max("v-large") {
            top: 130px;
        }
        @include breakpoint-max("desktop") {
            max-width: 400px;
        }
        @include breakpoint-max("notebook") {
        position: relative;
        max-width: 70%;
        top: initial;
        right: initial;
        padding: 20px;
        }
        @include breakpoint-max("mobile") {
        max-width: 100%;
        }
    }
}
.flex {
    .only-text__content {
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 10px 40px #0000001A;
        padding: 50px 0;
        align-items: center;
        z-index: 999;
        position: relative;
        /deep/ h2 {
            text-transform: none;
            width: 50%;
        }
    }
}
 .dark-info {
     color: #fff;
    .only-text__bg {
        background: #000;
    }
    /deep/ h2 {
        color: #fff;
        text-transform: none;
        max-width: 510px;
        text-align: left;
    }
    /deep/ .first-line {
        display: flex;
        justify-content: space-between;
        @include breakpoint-max("tablet") {
        flex-direction: column;
        }
        p {
        text-align: left;
        font-size: 16px;
        margin: 10px 0 10px;
        }
    }
    /deep/ .second-line {
        display: flex;
        margin-top: 100px;
        justify-content: space-between;
        font-size: 28px;
        align-items: center;
        @include breakpoint-max("tablet") {
        flex-direction: column;
        font-size: 20px;
        }
        div {
            display: flex;
            align-items: center;
            img {
                margin-right: 20px;
            }
        }
    }
    .only-text__content {
        padding: 100px 0;
        max-width: 1389px;
    }
    /deep/ .button-reserve {
        margin-top: 70px;
        width: 309px;
        height: 55px;
        background: #FB4240;
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            color: #fff;
        }
    }
}
.clause {
    font-size: 14px;
}
.smart {
    /deep/ .smart-flex {
        display: flex;
        text-align: left;
        @include breakpoint-max("tablet") {
        flex-direction: column;
        }
    }
    /deep/ .smart-flex-wrap {
        margin-left: 50px;
        width: 70%;
        p {
            min-height: 100px;
        }
    }
    /deep/ .smart-button {
        width: 209px;
        height: 55px;
        margin: 20px 0;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
        color: #fff;
        margin: 0;
        margin-left: 10px;
        
        }
    }
    /deep/ .smart-flex-container {
        flex: 1;
    }
    .only-text__content {
        padding: 100px 0;
        max-width: 1389px;
        /deep/ h2 {
        text-transform: none;
        max-width: 700px;
        text-align: left;
        margin-bottom: 50px;
        }
         /deep/ h3 {
            font-size: 30px;
            margin: 20px 0;
        }
    }

}
</style>
